import { TranslateResult } from 'vue-i18n';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State as ClassState } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import BigNumber from 'bignumber.js';
import Loader from '@/components/common/loader/Loader.vue';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import ProgressBar from '@/components/common/progress-bar/ProgressBar.vue';
import StaticErrors from '@/components/common/StaticErrors/StaticErrors.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { dividendsReturnsOptions } from '@/components/common/net-value/dividendsReturnsOptions';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
import { formatNumber } from '@/filters/number';
import CheckoutFooter from '@/components/checkout/footer/CheckoutFooter.vue';
import { Checkout, CheckoutStepNames } from '@/store/models/checkout';
import { PaymentInitData } from '@/store/modules/payment';
import { Valuation } from '@/store/models/valuation';
import { requireContractAgreement } from '../../../../whitelabel.config';

@Component({
  components: {
    Loader,
    ProgressBar,
    StaticErrors,
    WithDownloads,
    [VueNumberInput.name]: VueNumberInput,
    ValidationProvider,
    ValidationObserver,
    FormInvalidMessage,
    CheckoutFooter,
  },
})

export default class CheckoutInvestment extends Vue {
  sharesAmount: number = 0;
  selectedDividendsFormatYear: [string, number] = ['0', 0];
  // Based on the whitelabel.config show/skip the contract agreement step
  requireContractAgreement: boolean = requireContractAgreement;
  formatNumber = formatNumber;

  @Action(addToastMessage) addToastMessage!: Function;
  @Action paymentInit!: (data: PaymentInitData) => void;
  @Action resetPayment!: Function;
  @Action updateCheckoutAction!: Function;
  @Action resetCheckoutAction!: (initValue?: Partial<Checkout>) => void;

  @ClassState payment!: State['payment'];
  @ClassState idin!: State['idin'];
  @ClassState user!: State['user'];

  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

  @Getter getAssetById!: (string) => Asset;
  @Getter isEligibleToInvest!: boolean;
  @Getter getCheckout!: Checkout;
  @Getter('getAssetAddress') assetAddress!: string;
  @Getter getActiveValuationByAsset!: Function;
  @Getter getAssetValuationSharePrice!: Function;

  mounted(): void {
    const resetStore = this.fundId !== this.getCheckout.assetId;
    if (resetStore) {
      this.resetCheckoutAction({
        eurAmount: this.inputMin,
        nextStep: this.getCheckout.nextStep,
      });
    }
    this.sharesAmount = this.getCheckout!.sharesAmount;
    this.selectedDividendsFormatYear = this.getCheckout!.selectedDividendsFormatYear;
    if (!resetStore) {
      this.form.validate();
    }
  }

  created(): void {
    this.resetPayment();
  }

  @Watch('isGTMDataAvailable', { immediate: true })
  changeToisGTMDataAvailable(val, oldVal): void {
    if (val && !oldVal) {
      try {
        this.$gtm.trackEvent({
          event: 'invest_visit',
          user: this.user?.id,
          email: this.user?.email,
          transactionProducts: [{
            content_type: 'Product',
            sku: this.asset?.id,
            name: this.asset?.name,
            price: this.asset?.sharePrice,
            currency: 'EUR',
          }],
        });
      } catch (e) { /* Silent error */ }
    }
  }

  get assetValuation(): Valuation | undefined {
    return this.asset && this.getActiveValuationByAsset(this.asset.id);
  }

  get assetSharePrice(): number | undefined {
    return this.asset && this.getAssetValuationSharePrice(this.asset.id);
  }

  get isGTMDataAvailable(): boolean {
    return (!!this.user?.email && !!this.asset?.name);
  }

  get inputMin(): number {
    return (
      (this.assetSharePrice && this.asset?.euroMin)
      ? new BigNumber(this.asset!.euroMin).dividedBy(this.assetSharePrice).integerValue().toNumber()
      : 0
    );
  }

  get inputMax(): number {
    if (!this.asset) {
      return 0;
    }
    return this.asset.sharesAvailable;
  }

  get dividendsReturnsOptions(): [string, number][] {
    return dividendsReturnsOptions(this.asset);
  }

  /**
   * When to disable the payment button.
   */
  get disablePaymentButton(): boolean {
    return !!this.paymentLoading || !this.asset || !!this.paymentSuccess || this.asset.sharesAvailable <= 0
      || (this.asset.dividendsFormat.length > 0 && this.selectedDividendsFormatYear[0] === '0');
  }

  get fundId(): string {
    return this.$route.params.id;
  }

  get asset(): Asset | undefined {
    return this.getAssetById(this.fundId);
  }

  get emissionCost(): number {
    return this.asset?.emissionCost || 0;
  }

  get totalEmissionCosts(): number {
    return this.emissionCost
    ? new BigNumber(this.euroAmount).times(this.emissionCost).dividedBy(100).decimalPlaces(2)
      .toNumber()
    : 0;
  }

  get totalEur(): number {
    return new BigNumber(this.sharesAmount).times(this.assetSharePrice ? this.assetSharePrice : this.asset!.sharePrice).plus(this.totalEmissionCosts)
      .toNumber();
  }

  get euroAmount(): number {
    return new BigNumber(this.sharesAmount).times(this.assetSharePrice ? this.assetSharePrice : this.asset!.sharePrice).toNumber();
  }

  get placeHolderImage(): Object {
    return require('@/assets/images/property/properties-placeholder.jpg');
  }

  /**
   * Returns whether payment is loading/processing.
   *
   * @returns {boolean}
   */
  get paymentLoading(): boolean {
    return !!this.payment && this.payment.status === 'processing';
  }

  /**
   * Returns whether payment is a success.
   *
   * @returns {boolean}
   */
  get paymentSuccess(): boolean {
    return !!this.payment && this.payment.status === 'success';
  }

  get investorLoading(): boolean {
    return !this.user;
  }

  // eslint-disable-next-line consistent-return
  get nextStep(): { text: string | TranslateResult, note: string | TranslateResult | null, onClick: Function } | undefined {
    const updateCheck = (): void => {
      this.updateCheckoutAction({
        assetId: this.fundId,
        eurAmount: this.euroAmount,
        selectedDividendsFormatYear: this.selectedDividendsFormatYear,
        sharesAmount: this.sharesAmount,
        totalEur: this.totalEur,
        totalEmissionCosts: this.totalEmissionCosts,
        assetValuationId: this.assetValuation ? this.assetValuation.id : undefined,
      });
    };
    switch (this.getCheckout.nextStep) {
      case CheckoutStepNames.Questionnaire:
        return {
          text: 'Submit',
          note: 'And go to next step',
          onClick: (): void => {
            updateCheck();
            this.$router.push({ path: `/${this.$route.params.lang}/checkout/questionnaire/${this.getCheckout.assetId}/` });
          },
        };
      case CheckoutStepNames.Terms:
        return {
          text: this.$t('checkout.investment.legalTerms'),
          note: this.$t('checkout.investment.finalize'),
          onClick: (): void => {
            updateCheck();
            this.$router.push({ path: `/${this.$route.params.lang}/checkout/legal/${this.getCheckout.assetId}/` });
          },
        };
      case CheckoutStepNames.Payment:
        return {
          text: 'Select',
          note: this.$t('checkout.legal.continue'),
          onClick: (): void => {
            updateCheck();

            this.paymentInit({
              redirectUrl: `${window.location.origin}/checkout/status/`,
              lang: localStorage.getItem('vue-i18n-language') || 'nl',
              brand: require('../../../../whitelabel.config').brand,
            });
          },
        };
      default:
        return {
          text: `Move to ${this.getCheckout.nextStep}`,
          note: null,
          onClick: (): void => {
            updateCheck();
          },
        };
    }
  }

  updateAsset(newValue, oldValue): void {
    try {
      this.$gtm.trackEvent({
        event: 'begin_checkout',
        user: this.user?.id,
        email: this.user?.email,
        transactionId: this.$route.params.paymentId,
        transactionTotal: this.totalEur,
        transactionProducts: [{
          content_type: 'Product',
          sku: this.asset?.id,
          name: this.asset?.name,
          price: this.asset?.sharePrice,
          currency: 'EUR',
        }],
      });
    } catch (e) { /* Silent error */ }
  }
}
