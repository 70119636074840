import { Vue, Component, Watch } from 'vue-property-decorator';
import { Getter, State as ClassState } from 'vuex-class';
import { collections } from '@/vue';
import Loader from '@/components/common/loader/Loader.vue';
import { Payment, PaymentStatus } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';
import CheckoutFooter from '@/components/checkout/footer/CheckoutFooter.vue';
import { State } from '@/store/models';

@Component({
  components: {
    Loader,
    CheckoutFooter,
  },
})

export default class CheckoutStatus extends Vue {
  collections = collections;

  @Getter getAssetByInvestmentId!: Function;
  @Getter getPaymentById!: Function;

  @ClassState user!: State['user'];

  /**
   * Get payment
   *
   * @returns {Investment}
   */
  get payment(): Payment | undefined {
    return this.getPaymentById(this.$route.params.paymentId);
  }

  get asset(): Asset {
    return this.payment?.asset as Asset;
  }

  /**
   * Return quantity of Bloqs bought.
   *
   * @returns {number}
   */
  get sharesBought(): number {
    return Number(this.payment?.providerData.metadata!.sharesAmount);
  }

  /**
   * Return amount of EUR bought.
   *
   * @returns {number}
   */
  get euroBought(): number {
    return this.payment?.providerData.metadata.euroAmount || 0;
  }

  /**
   * Return payment status
   *
   * @returns {number}
   */
  get paymentStatus(): PaymentStatus | undefined {
    return this.payment?.providerData.status;
  }

  @Watch('paymentStatus') onPaymentStatusChange(newPaymentStatus): void {
    if (!this.paymentStatus) {
      return;
    }

    if (this.paymentStatus !== PaymentStatus.Open && this.paymentStatus !== PaymentStatus.Paid) {
      try {
        this.$gtm.trackEvent({
          event: 'finish_checkout',
          status: this.paymentStatus,
          user: this.user?.id,
          email: this.user?.email,
          transactionId: this.$route.params.paymentId,
          transactionTotal: this.payment?.providerData.metadata.euroAmount,
          transactionProducts: [{
            content_type: 'Product',
            sku: this.asset?.id,
            name: this.asset?.name,
            price: this.asset?.sharePrice,
            currency: 'EUR',
            quantity: this.payment?.providerData.metadata.sharesAmount,
          }],
        });
      } catch (e) { /* Silent error */ }
    }

    if (this.paymentStatus === PaymentStatus.Paid) {
      try {
        this.$gtm.trackEvent({
          event: 'purchase',
          user: this.user?.id,
          email: this.user?.email,
          transactionId: this.$route.params.paymentId,
          transactionTotal: this.payment?.providerData.metadata.euroAmount,
          transactionProducts: [{
            content_type: 'Product',
            sku: this.asset?.id,
            name: this.asset?.name,
            price: this.asset?.sharePrice,
            currency: 'EUR',
            quantity: this.payment?.providerData.metadata.sharesAmount,
          }],
        });
      } catch (e) { /* Silent error */ }
    }
  }

  goBackToPortfolio(): void {
    this.$router.replace('/account').catch((): void => {});
  }

  // Opening the support box from AccountDashboard
  handleSupport(): void {
    this.$emit('handle-support-event');
  }
}
