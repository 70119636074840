import { Vue, Component, Watch } from 'vue-property-decorator';
import { State as ClassState, Getter, Action } from 'vuex-class';
import to from 'await-to-js';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import Loader from '@/components/common/loader/Loader.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import CheckoutFooter from '@/components/checkout/footer/CheckoutFooter.vue';
import CheckoutRecap from '@/components/checkout/recap/CheckoutRecap.vue';
import Lightbox from '@/components/common/lightbox/Lightbox.vue';
import { State } from '@/store/models';
import { BusinessInvestor, PrivateInvestor } from '@/store/models/user';
import { formatNumber } from '@/filters/number';
import { Checkout } from '@/store/models/checkout';
import { Asset } from '@/store/models/asset';
import { PaymentInitData } from '@/store/modules/payment';

interface ContractData {
  name: string,
  surname: string,
  dateOfBirth: number,
  streetAddress: string,
  postalCode: string,
  city: string,
  email: string,
}

interface DocumentMeta {
  name: string,
  signed: boolean,
  showLightbox: boolean,
}

interface RequestResponse {
  data: {
    uid: string,
    amount: number,
    totalAmount: number,
    sharesAmount: number,
    investmentId: string,
    paymentId: string,
    payment: object
  }
}

@Component({
  components: {
    CheckoutFooter,
    WithDownloads,
    CheckoutRecap,
    Lightbox,
    Loader,
  },
})

export default class CheckoutLegal extends Vue {
  formatNumber = formatNumber;
  isLoading: boolean = false;

  @Getter isEligibleToInvest!: boolean;
  @Getter getCheckout!: Checkout;
  @Getter getAssetById!: (string) => Asset;

  @ClassState user!: State['user'];

  @Action(addToastMessage) addToastMessage!: Function;
  @Action paymentInit!: (data: PaymentInitData) => any;

  documentMeta: DocumentMeta[] = [
    { name: 'Ik heb de informatie nota over deze aanbieding gelezen en goedgekeurd.', signed: false, showLightbox: false },
    { name: 'Ik heb de algemene uitgiftevoorwaarden inschrijvingsovereenkomst gelezen en goedgekeurd.', signed: false, showLightbox: false },
  ]

  get asset(): Asset | undefined {
    return this.getAssetById(this.getCheckout?.assetId);
  }

  get isSigned(): boolean {
    return this.documentMeta.every((doc): boolean => doc.signed);
  }

  @Watch('isSigned', { immediate: true }) onAgreeLegal(newIsSigned): void {
    if (newIsSigned) {
      try {
        this.$gtm.trackEvent({
          event: 'add_legal_info',
          user: this.user?.id,
          email: this.user?.email,
          transactionId: this.$route.params.paymentId,
          transactionProducts: [{
            content_type: 'Product',
            sku: this.asset?.id,
            name: this.asset?.name,
            price: this.asset?.sharePrice,
            currency: 'EUR',
          }],
        });
      } catch (e) { /* Silent error */ }
    }
  }

  get contractData(): ContractData | null {
    if (!this.user) {
      return null;
    }

    // NOTE: Since there is no iDIN in Randstad the user was asserted to be of private or business types. This was necessary as
    // the `name` field is required for the contract data
    const { name, surname, email, dateOfBirth, city, postalCode, streetAddress } = this.user as PrivateInvestor | BusinessInvestor;

    return {
      name,
      surname,
      email,
      dateOfBirth: dateOfBirth.toMillis(),
      city,
      postalCode,
      streetAddress,
    };
  }

  async initPayment(): Promise<void> {
    this.isLoading = true;
    const [paymentRequestError, paymentRequestSuccess] = await to<RequestResponse>(
      this.paymentInit({
        redirectUrl: `${window.location.origin}/checkout/status/`,
        lang: localStorage.getItem('vue-i18n-language') || 'nl',
        brand: require('../../../../whitelabel.config').brand,
      }),
    );

    this.isLoading = false;
    if (paymentRequestSuccess) {
      const { data: { investmentId, paymentId } } = paymentRequestSuccess;
      this.$router.replace(`/checkout/status/${investmentId}/${paymentId}`).catch((): void => {});
    } else {
      // Error while creating the payment process. Please try again.
      this.addToastMessage({
        text: 'Fout bij het maken van het betalingsproces. Probeer het opnieuw.',
        type: 'danger',
      });
    }
  }
}
